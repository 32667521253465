<template>
  <el-select
    filterable
    remote
    v-model="userBusinessInvoiceId"
    :placeholder="placeholder"
    @change="onChangeSelect"
    :remote-method="getInvoices"
    :loading="loading"
  >
    <el-option
      v-for="invoice in invoices"
      :key="invoice.id"
      :label="invoice.number_invoice"
      :value="invoice.id"
    ></el-option>
  </el-select>
</template>

<script>
import invoiceService from "../sales/pages/invoices/services/invoiceService";

export default {
  name: "SelectInvoiceComponent",
  data() {
    return {
      loading: false,
      invoices: [],
      userBusinessInvoiceId: this.setUserBusinessInvoiceId
    };
  },
  watch: {
    setUserBusinessInvoiceId(v) {
      this.userBusinessInvoiceId = v;
    }
  },
  computed: {
    findInvoice() {
      return this.invoices.find(
        invoice => invoice.id === this.userBusinessInvoiceId
      );
    }
  },
  methods: {
    onChangeSelect() {
      this.$emit("onChangeSelect", {
        invoice: this.findInvoice
      });
    },
    async getInvoices(query) {
      this.invoices = [];
      this.loading = true;
      await invoiceService
        .getInvoices({
          params: {
            status: "success",
            search: query
          }
        })
        .then(response => {
          if (response.data.data) this.invoices = response.data.data;
        })
        .finally(() => (this.loading = false));
    }
  },
  props: {
    placeholder: {
      default: "Seleccionar"
    },
    setUserBusinessInvoiceId: {
      default: null
    }
  },
  mounted() {
    this.getInvoices();
  }
};
</script>

<style lang="sass" scoped></style>
