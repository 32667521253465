import Api from "@/config/Api";

export default {
  getDebitNotes(options = {}) {
    return Api().get(`/debit-notes`, options);
  },
  getDebitNoteId(debitNoteId) {
    return Api().get(`/debit-notes/${debitNoteId}`);
  },
  addDebitNotes(data) {
    return Api().post("/debit-notes", data);
  },
  updateDebitNoteId(debitNoteid, data) {
    return Api().put(`/debit-notes/${debitNoteid}`, data);
  },
  deleteDebitNoteId(debitNoteid) {
    return Api().delete(`/debit-notes/${debitNoteid}`);
  },
  sendDebitNoteSRI(data) {
    return Api().post(`/sri/signature/debit-note`, data);
  }
};
