<template>
  <section class="wrapper-document-calculator">
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate0.neto">
        <p class="font-semi-bold m-0">Subtotal IVA 0%</p>
        <h5 class="h5 font-bold"><FormatAmount :amount="calculate0.neto" /></h5>
      </div>
    </TransitionFadeComponent>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate5.neto">
        <p class="font-semi-bold m-0">Subtotal IVA 5%</p>
        <h5 class="h5 font-bold">
          <FormatAmount :amount="calculate5.neto" />
        </h5>
      </div>
    </TransitionFadeComponent>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate12.neto">
        <p class="font-semi-bold m-0">Subtotal IVA 12%</p>
        <h5 class="h5 font-bold">
          <FormatAmount :amount="calculate12.neto" />
        </h5>
      </div>
    </TransitionFadeComponent>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate13.neto">
        <p class="font-semi-bold m-0">Subtotal IVA 13%</p>
        <h5 class="h5 font-bold">
          <FormatAmount :amount="calculate13.neto" />
        </h5>
      </div>
    </TransitionFadeComponent>

    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate14.neto">
        <p class="font-semi-bold m-0">Subtotal IVA 14%</p>
        <h5 class="h5 font-bold">
          <FormatAmount :amount="calculate14.neto" />
        </h5>
      </div>
    </TransitionFadeComponent>

    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate15.neto">
        <p class="font-semi-bold m-0">Subtotal IVA 15%</p>
        <h5 class="h5 font-bold">
          <FormatAmount :amount="calculate15.neto" />
        </h5>
      </div>
    </TransitionFadeComponent>

    <div class="d-flex justify-content-between">
      <p class="font-semi-bold m-0">Subtotal</p>
      <h5 class="h5 font-bold"><FormatAmount :amount="subTotal" /></h5>
    </div>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate5.iva">
        <p class="font-semi-bold m-0">Valor IVA 5%</p>
        <h5 class="h5 font-bold"><FormatAmount :amount="calculate5.iva" /></h5>
      </div>
    </TransitionFadeComponent>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate12.iva">
        <p class="font-semi-bold m-0">Valor IVA 12%</p>
        <h5 class="h5 font-bold"><FormatAmount :amount="calculate12.iva" /></h5>
      </div>
    </TransitionFadeComponent>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate13.iva">
        <p class="font-semi-bold m-0">Valor IVA 13%</p>
        <h5 class="h5 font-bold"><FormatAmount :amount="calculate13.iva" /></h5>
      </div>
    </TransitionFadeComponent>
    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate14.iva">
        <p class="font-semi-bold m-0">Valor IVA 14%</p>
        <h5 class="h5 font-bold"><FormatAmount :amount="calculate14.iva" /></h5>
      </div>
    </TransitionFadeComponent>

    <TransitionFadeComponent>
      <div class="d-flex justify-content-between" v-if="calculate15.iva">
        <p class="font-semi-bold m-0">Valor IVA 15%</p>
        <h5 class="h5 font-bold"><FormatAmount :amount="calculate15.iva" /></h5>
      </div>
    </TransitionFadeComponent>

    <hr />
    <div class="d-flex justify-content-between">
      <h5 class="h5 font-bold">Valor Total</h5>
      <h5 class="h5 font-bold"><FormatAmount :amount="total" /></h5>
    </div>
  </section>
</template>

<script>
import FormatAmount from "@/components/General/FormatAmount";
import TransitionFadeComponent from "@/components/Transitions/Fade";

export default {
  name: "DocumentCalculatorNotes",
  data() {
    return {
      products: this.setProducts,
      discount: this.setDisc
    };
  },
  watch: {
    setDisc(v) {
      this.discount = v;
    },
    setProducts(v) {
      this.products = v;
    }
  },
  computed: {
    calculate0() {
      return this.calculates(0, this.products, this.discount);
    },
    calculate5() {
      return this.calculates(5, this.products, this.discount);
    },
    calculate12() {
      return this.calculates(12, this.products, this.discount);
    },
    calculate13() {
      return this.calculates(13, this.products, this.discount);
    },
    calculate14() {
      return this.calculates(14, this.products, this.discount);
    },
    calculate15() {
      return this.calculates(15, this.products, this.discount);
    },
    subTotalTaxes() {
      return (
        this.calculate0.iva +
        this.calculate5.iva +
        this.calculate12.iva +
        this.calculate13.iva +
        this.calculate14.iva +
        this.calculate15.iva
      );
    },
    subTotal() {
      return (
        this.calculate0.neto +
        +this.calculate5.neto +
        this.calculate12.neto +
        this.calculate13.neto +
        this.calculate14.neto +
        this.calculate15.neto
      );
    },
    subTotalDiscount() {
      return (
        this.calculate0.discount +
        this.calculate5.discount +
        this.calculate12.discount +
        this.calculate13.discount +
        this.calculate14.discount +
        this.calculate15.discount
      );
    },
    total() {
      return this.subTotal - this.subTotalDiscount + this.subTotalTaxes;
    }
  },
  methods: {
    calculates(percentage, products, discount = 0) {
      let neto = 0;
      let discountTotal = 0;
      products
        .filter(({ taxes_percentage }) => taxes_percentage === percentage)
        .forEach(
          ({ product_quantity, product_amount }) =>
            (neto += product_quantity * product_amount)
        );
      discountTotal = neto * (discount / 100);
      return {
        neto,
        iva: (neto - discountTotal) * (percentage / 100),
        discount: discountTotal
      };
    }
  },
  props: {
    setDisc: {
      default: 0
    },
    setProducts: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FormatAmount,
    TransitionFadeComponent
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
