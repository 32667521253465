<template>
  <div class="">
    <el-form :model="formDocument" :rules="rules" ref="formDocument">
      <TopBarTitleComponent title="Nueva Nota Débito" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>
      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col>
            <div class="card box-shadow">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="4">
                    <el-form-item prop="user_business_invoice_id">
                      <slot name="label">
                        <label class="font-semi-bold m-0 d-block"
                          >Facturas</label
                        >
                      </slot>
                      <SelectInvoiceComponent
                        :setUserBusinessInvoiceId="
                          formDocument.user_business_invoice_id
                        "
                        @onChangeSelect="onChangeSelectInvoices"
                      />
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="4">
                    <InputDatePickerComponent
                      @onChangeDatePicker="onChangeDatePicker"
                      prop="emission_date"
                      placeholder=""
                      title="Fecha de emisión"
                      :setDate="formDocument.emission_date"
                      :isDisabledDate="true"
                    />
                  </b-col>
                  <b-col xs="12" sm="12" md="4"></b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="12">
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="">
                          <slot name="label">
                            <label class="font-semi-bold m-0">Empresa</label>
                          </slot>
                          <el-input
                            readonly
                            :value="getClientInvoice && getClientInvoice.name"
                            type="text"
                            placeholder=""
                            class=""
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="">
                          <slot name="label">
                            <label class="font-semi-bold m-0">Correo</label>
                          </slot>
                          <el-input
                            readonly
                            :value="getClientInvoice && getClientInvoice.email"
                            type="text"
                            placeholder=""
                            class=""
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="">
                          <slot name="label">
                            <label class="font-semi-bold m-0">Direccion</label>
                          </slot>
                          <el-input
                            readonly
                            :value="
                              getClientInvoice && getClientInvoice.address
                            "
                            type="text"
                            placeholder=""
                            class=""
                          ></el-input>
                        </el-form-item>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <TableInputProducts
                  :setProducts="formDocument.products"
                  @onToogleProducts="onToogleProducts"
                />
                <section class="aditional-documents mt-4">
                  <b-row>
                    <b-col xs="12" sm="12" md="4"> </b-col>
                    <b-col xs="12" sm="12" md="4" offset-md="4">
                      <DocumentCalculatorNotes
                        :setProducts="formDocument.products"
                      />
                    </b-col>
                  </b-row>
                </section>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import InputDatePickerComponent from "../../../../components/InputDatePicker";
import TableInputProducts from "../../../components/TableInputProducts";
import SelectInvoiceComponent from "../../../../components/SelectInvoices";
import DocumentCalculatorNotes from "../../../components/DocumentCalculatorNotes";

import moment from "moment";
import Validate from "@/assets/validate";
import debitNoteService from "../services/debitNoteService";
const DATE_AT = moment().format("YYYY-MM-DD");

export default {
  name: "DebitNotesAddPage",
  data() {
    return {
      apiResponse: {
        message: null
      },
      invoice: null,
      formDocument: {
        user_business_invoice_id: "",
        emission_date: DATE_AT,
        discount: 0,
        products: []
      },
      rules: {
        user_business_invoice_id: [Validate.rules.required("change")],
        emission_date: [Validate.rules.required()]
      }
    };
  },
  computed: {
    getClientInvoice() {
      return this.invoice ? this.invoice.client : null;
    }
  },
  methods: {
    onChangeSelectInvoices({ invoice }) {
      const { id } = invoice;
      this.formDocument.user_business_invoice_id = id;
      this.invoice = invoice;
    },
    handleSaveClick() {
      this.$refs.formDocument.validate(async valid => {
        if (valid) {
          if (!this.formDocument.products.length) {
            this.$notifications.warning({
              message: "Debes seleccionar al menos un producto/servicio."
            });
            return false;
          }
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await debitNoteService
              .addDebitNotes(this.formDocument)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message, data: debitNote } = response.data;
            if (success) {
              this.$refs.formDocument.resetFields();
              this.$notifications.success({
                message
              });
              this.goRouter("sales.debitNote.view", {
                id: debitNote.id
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeDatePicker({ date }) {
      this.formDocument.emission_date = date;
    },
    onToogleProducts({ products }) {
      const p = products.map(p => {
        p.is_debit_note = 1;
        return p;
      });
      this.formDocument.products = [...p];
    }
  },
  components: {
    ButtonPrimaryComponent,
    TopBarTitleComponent,
    InputDatePickerComponent,
    TableInputProducts,
    SelectInvoiceComponent,
    DocumentCalculatorNotes
  },
  mounted() {}
};
</script>

<style lang="sass" scoped></style>
