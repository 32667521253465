import Api, { configFormData } from "@/config/Api";

export default {
  getInvoices(options = {}) {
    return Api().get(`/invoices`, options);
  },
  getClientInvoices(clientId, options = {}) {
    return Api().get(`/invoices?client=${clientId}`, options);
  },
  addInvoices(data) {
    return Api().post(`/invoices`, data);
  },
  getInvoceId(invoiceId) {
    return Api().get(`/invoices/${invoiceId}`);
  },
  deleteInvoceId(invoiceId) {
    return Api().delete(`/invoices/${invoiceId}`);
  },
  sendInvoiceEmail({ data }) {
    return Api().post(`/invoices/send-email`, data, configFormData);
  },
  sendInvoiceSRI(data) {
    return Api().post(`/sri/signature/invoice`, data);
  },
  getPayMethods() {
    return Api().get(`/payments-type`, {});
  },
  addPayments(data) {
    return Api().post("/me/payments", data);
  },
  updatePaymentsId(paymentId, data) {
    return Api().put(`/me/payments/${paymentId}`, data);
  },
  deletePaymentsId(data) {
    return Api().delete(`/me/payments/${data.id}`);
  },
  updateInvoiceId(invoiceId, data) {
    return Api().put(`/invoices/${invoiceId}`, data);
  },
  updateInvoiceStatus(data) {
    return Api().post(`/status/invoices`, data);
  },
  addProductsToInvoice(data) {
    return Api().post("/invoices/products", data);
  },
  updateProductsToInvoice(productId, data) {
    return Api().put(`/invoices/products/${productId}`, data);
  },
  deleteProductsToInvoice(productId) {
    return Api().delete(`/invoices/products/${productId}`);
  },
  invoiceDownload({ id }) {
    return Api().get(`/u/download/invoices?id=${id}`, {
      responseType: "arraybuffer"
    });
  },
  invoicePaid(data) {
    return Api().post("/invoices/paid", data);
  },
  invoiceDownloadXML(userBusinessId, data) {
    return Api().post(`/invoices/downloadxml/${userBusinessId}`, data, {
      responseType: "blob"
    });
  }
};
